.rescan-project-list-popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(19, 19, 34, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-in-out;
    /*make it smooth*/

}

.rescan-project-list-popup-wrapper-show {
    opacity: 1;
    visibility: visible;
    animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.rescan-project-popup-inside {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2d1d0b1a;
    border-radius: 5px;
    padding: 30px 50px;
    transform: translateY(80px);
    opacity: 0;
    transition: 1s ease-in-out;
    visibility: hidden;
    position: relative;
    z-index: 1;
}

.rescan-project-list-popup-wrapper-show .rescan-project-popup-inside {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}


.rescan-popup-close-button {
    text-align: right;
    margin-bottom: 2rem;
}

.rescan-popup-close-button img{
    cursor: pointer;
    margin-right: -30px;
}

.rescan-popup-description-side {
    margin-top: 2rem;
    text-align: center;
}

.rescan-popup-description-side p{
    margin: 1rem 0 2rem;
    color: #1C1E21;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.scanning-loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-7 {
    width: 50px;
    height: 50px;
    --c: radial-gradient(farthest-side, #3D2CB2 92%, #0000);
    background:
        var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    animation: s7 1s infinite;
}

@keyframes s7 {
    to {
        transform: rotate(.5turn)
    }
}


.rescan-popup-description-side button {
    background: #3D2CB2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: block;
    border: none;
    outline: none;
    color: #fff;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding: 12px 30px;
    margin-top: 2rem;
}
