.auth.header-wrapper {
    background: #3D2DB2!important;
}

.headershadow-dropdown{
    background-color: #fff!important;
}
.headershadow{
    background: #fff!important;
}

.github-scan-result{
    position: relative;
}

.github-scan-result-cards-wrapper {
    margin: 3rem 0 0;
}

.github-scan-result-cards-wrapper-overflow{
    margin: auto;
    padding: 0 7px;
}

.github-scan-result-cards-inside {
    padding: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2D1D0B33;
    border-radius: 5px;
}

.github-scan-project-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    border-bottom: 2px solid rgba(45, 29, 11, 0.1);
    padding-bottom: 20px;
}

.github-scan-result h2{
    font-size: 2.4rem;
    font-weight: 650;
}

.quick-option-list h3{
    font-size: 2rem;
    font-weight: 650;
    color: #5B4423;
    margin-bottom: 0;
    margin-top: 1.3rem 0 0.5rem;
}

.risk-side {
    display: flex;
    gap: 15px;
    align-items: center;
}

.risk-side img {
    width: 40px;
}

.risk-side p.low-risk-text {
    margin: 0;
    font-weight: 500;
    color: #90CA2F;
}

.low-risk {
    color: #00E020;
}

.critical-risk {
    color: #FF0000;
}

.high-risk {
    color: #FF9C20;
}

.medium-risk {
    color: #FFE000;
}

.unknow-risk {
    color: #655FD6;
}

.github-scan-quick-option{
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-bottom: 2px solid rgba(45, 29, 11, 0.1);
}

.github-scan-quick-option img{
    width: 50px;
    margin-bottom: 10px;
}

.github-scan-score-result{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    padding: 30px 0 0;
}

.github-scan-score-result-list{
    display: flex;
    gap: 20px;
    align-items: center;
    flex: 1 1 18%;
    border-right: 1px solid rgba(45, 29, 11, 0.1);
    justify-content: center;
    padding-right: 10px;
}

.github-scan-score-result-list:nth-of-type(5){
    border-right: 0;
}

.github-scan-score-result-list h4{
    font-size: 3.4rem;
    margin: 0;
}
.github-scan-score-result-list p{
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
}

@media(max-width:767px) {
    .github-scan-quick-option{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .quick-option-list{
        margin-bottom: 15px;
    }
    .quick-option-list:nth-of-type(3){
        margin-bottom: 0;
    }
    .github-scan-score-result{
        flex-wrap: wrap;
    }
    .github-scan-score-result-list{
        gap: 5px;
        flex-direction: column;
        flex: 1 1 26%;
        margin-bottom: 4rem;
    }
    .github-scan-score-result-list:nth-of-type(4),.github-scan-score-result-list:nth-of-type(5){
        margin-bottom: 0;
    }
    .github-scan-score-result-list:nth-of-type(3){
        border-right: 0;
    }
    .github-scan-result-cards-wrapper-overflow{
        width: auto;
        padding: 0;
    }
}

.github-scan-project-heading p{
    font-size: 2.2rem;
    color: #2D1D0B;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0;
}

/* ----------------------------------------------------------------------------------------------------------------------- */

/* New media query for updated design */
