.form-background {
    position: relative;
    padding-top: 50px;
    margin-top: -2px;
}

.form-background::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20%;
    z-index: -1;
    background: linear-gradient(180deg,#3d2db2 0%,#1f1659 100%);
    background-image: url('https://f.hubspotusercontent-eu1.net/hubfs/24902366/banner-suport-1.svg');
}

.form-background::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20%;
    z-index: -2;
    background: linear-gradient(180deg,#3d2db2 0%,#1f1659 100%);
}

.form-inside-wrapper-imageSide {
    height: 100%;
}

.image-leftside {
    text-align: right;
    align-self: flex-end;
}

.image-leftside-wrapper {
    position: relative;
    left: 135px;
    z-index: 10;
    top: 8px;
}

.image-leftside img {
    width: 140px
}

.image-rightside {
    text-align: left;
    align-self: flex-end;
}

.image-rightside-wrapper {
    position: relative;
    left: -140px;
    z-index: 10;
    top: 20px;
}

.image-rightside img {
    width: 100px;
}

/* common in forms */

.form-wrapper p, input {
    font-family: 'Lato', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Form background ---------------------------------------------------------------------*/

@media (min-width:768px) and (max-width:1023px) {
    .form-background .form-wrapper {
        width: 100%;
    }
    .image-rightside-wrapper {
        left: -105px;
    }
    .image-leftside-wrapper {
        left: 105px;
    }
}

@media (max-width:767px) {
    .form-inside-wrapper-imageSide {
        display: none;
    }
    .form-background .form-wrapper{
        width: 95%!important;
        margin-bottom: 40px!important;
    }
}
