.Sidebar {
    position: fixed;
    height: 100%;
    width: 92px;
    z-index: 3;
    top: 0;
    background-color: #1C1E21;
    overflow-x: hidden;
    padding-top: 10px;
}

.navItemsWrapper {
    text-align: center;
    padding: 20px 0;
}

.navLogo {
    margin-bottom: 40px;
}

.navLogo img {
    width: 80px;
}

.navitems {
    padding: 13px 5px 8px 5px;
    margin: 20px 5px;
    cursor: pointer;
}

.navitems:hover, .actived {
    background: #573BD3 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.navitems img {
    width: 23px;
}

.navitems:last-of-type img {
    width: 35px;
}

.navitems p {
    margin: 0rem;
    margin-top: 0.7em;
    font-size: 1.2rem;
    color: #fff;
}

/* for every layout */

.layout-wrapper {
    background-color: #F0F2F4;
    margin-top: 0;
    padding: 50px 0;
}

@media (min-width:1024px) {

    /* for every layout */
    .extra-margin {
        padding-left: 110px;
    }

}

.header-wrapper.backgroundWhite{
    display: none;
}

@media (max-width:1023px) {
    .Sidebar {
        display: none;
    }
    .header-wrapper.backgroundWhite{
        display: block;
        background-color: #fff;
    }
    .no-border{
        border-top: none!important;
    }
}

.nav-li .item button {
    background: #2D1D0B;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}


.nav-li.item.button button {
    color: #ffff;
    border: none;
    background: #2D1D0B 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 5px 10px;
}

img.down-arrow-image-sidebar {
    width: 37px;
}

#workspace {
    display: none;
}

.filter-arrow-show {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    visibility: visible;
}

.close-button {
    font-size: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: -31px;
    box-shadow: none;
    padding: 0;
    float: right;
    color: black;
}
