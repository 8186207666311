/* Modal Popup  */

.center a:hover {
    color: transparent;
    cursor: pointer;
}

.urlForm-modal {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;

}

.urlForm-modal-show{
    visibility: visible;
    opacity: 1;
}

.urlForm-modal-show .url-form-wrapper{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}

.url-form-wrapper {
    width: 60%;
    background-color: #fff;
    position: relative;
    overflow-y: scroll;
    padding: 50px 30px;
    transform: translateY(-600px);
    transition: 0.6s ease-in-out;
    opacity: 0;
    visibility: hidden;
/* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.url-form-wrapper::-webkit-scrollbar {
    display: none;
}

.modal-close {
    position: absolute;
    top: 0px;
    right: 15px;
    color: black;
    text-decoration: none;
    font-size: 4rem;
    cursor: pointer;
}

/* Modal Form */

.url-form-inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.url-form-imageside {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.url-form-imageside img {
    width: 65%;
}

.url-form-wrapper .url-form-inside h3 {
    margin-bottom: 0rem;
}

.url-form-inside p.error {
    font-size: 1.4rem!important;
    margin-top: 0.5rem!important;
}

.url-form-inside .form-heading p {
    margin-bottom: 20px;
}

.url-form-inside .form-field img {
    position: absolute;
    padding: 19px 0 10px 20px;
    width: 40px;
}

.url-form-inside .form-field img:nth-of-type(2) {
    right: 30px;
}

.url-form-inside .form-field p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    margin-top: 10px;
}

.url-form-inside .form-field input[type='text'] {
    text-align: left;
    height: 55px;
    border-radius: 5px;
    width: 100%;
    padding-left: 50px;
    color: #161616;
    margin-bottom: 5px;
    border: 1px solid #16161634;
    margin-left: 0!important;
}

.url-form-inside .form-field select {
    appearance: none;
    text-align: left;
    height: 45px;
    border-radius: 5px;
    width: 100%;
    padding-left: 50px;
    padding-right: 40px;
    color: #5B4423;
    font-family: 'Lato', sans-serif;
    border: 1px solid #16161634;
}

.url-form-inside .form-field input:focus, .form-field select:focus {
    outline: none;
    border: 1px solid #0A2854!important;
}

.url-form-inside .get-result {
    appearance: none;
    -webkit-appearance: none;
    display: block;
    width: auto;
    background-color: #3D2CB2;
    color: #fff;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 0;
}

.url-form-inside .form-field input::placeholder, .form-field select option {
    font-family: 'Lato', sans-serif;
    font-weight: 380;
    color: #1C1E21;
    opacity: 0.6;
}

@media (max-width: 767px) {
    .row-reverse {
        flex-direction: column-reverse !important;
    }
    .url-form-inside .get-result{
        padding: 12px 30px;
        width: auto;
    }
    .url-form-inside h3 {
        width: 100%;
        text-align: left;
        margin-top: 20px;
    }
    .urlForm-modal .modal_form{
        margin-top: 15px;
        overflow-y: scroll;
    }
    .url-form-imageside img {
        width: 55%;
    }
    .url-form-wrapper{
        padding: 50px 20px;
    }
    .url-form-inside{
        padding: 20px 0px;
    }
    .url-form-imageside {
       justify-content: center;
       text-align: left;
    }
}
.errors{
    display: none ;
}
.errors.has-error{
    color: #dc3545;
    display: block;
}

@media(max-width:1024px){
    .url-form-wrapper{
        width: 90%;
    }
}