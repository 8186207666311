.scan-details-filter-icon {
    display: none;
    position: relative;
}

.scan-details-filter-icon img{
    transition: 0.5s ease-in-out;
}

.scan-details-filter-icon-popup-modal-show img{
    transform: rotate(180deg);
}

.codebase-scan-result-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media(max-width:1140px) {
    .scan-details-filter-icon {
        margin: 7rem 0 0;
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
    }

    .scan-details-filter-icon p {
        margin-bottom: 0;
        font-size: 2.2rem;
    }
}

/* -------------------------------------------------------------------------------------------------------- */

.scan-details-filter-icon-popup-modal {
    position: absolute;
    top: 60px;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0 0 11px rgb(33 33 33 / 20%);
    border-radius: 5px;
    overflow: hidden;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: 0.5s ease-in-out;
    right: 0;
    border: 1px solid #ccc;
    z-index: 1;
    min-width: 350px;
}


.scan-details-filter-icon-popup-modal-show .scan-details-filter-icon-popup-modal{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.scan-details-filter-icon-popup-form form > div {
    margin-bottom: 25px;
}

.scan-details-filter-icon-popup-form form > div:last-of-type{
    margin-bottom: 0;
}

.scan-details-filter-icon-popup-form form label{
    line-height: 120%!important;
}

@media(max-width:767px){
    .scan-details-filter-icon-popup-modal {
        min-width: 280px;
    }
}
