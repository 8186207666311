.email-picker {
    padding: 20px 40px;
    border: 1px solid #4E545D65;
    max-width: 70%;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
  }
  
  .enter-emails-section input {
    border: none;
    display: inline-block;
    border-radius: 15px;
    padding-left: 10px;
    color: rgba(0, 0, 0, .87);
    font-size: 2rem;
  }
  
  .enter-emails-section input:focus {
    outline: none;
  }
  
  .enter-emails-section input::placeholder{
    font-weight: 200;
  }
  
  #selecionados {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  div.selecionado {
    padding: 8px;
    display: flex;
    line-height: 30px;
    padding-left: 10px;
    color: #161616;
    border-radius: 5px;
    background: #F5DF4D;
    margin-right: 5px;
    transition: all 300ms ease-in-out;
    position: relative;
    padding-right: 40px;
    font-size: 2rem;
  }
  
  div.selecionado.error {
    background: #E10051;
  }
  
  div.selecionado.error .excluir i {
    color: #E10051;
  }
  
  div.selecionado .excluir {
    position: absolute;
    right: 5px;
    top: 14px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    line-height: 20px;
    background: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .selecionado .excluir:hover {
    background: white;
  }
  
  .selecionado .excluir i {
    color: #2d2d2d;
    font-size: 12px;
    line-height: 10px;
  }
  
  .tooltip{
    display: none;
  }
  