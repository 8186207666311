.footer-wrapper .discuss-modal-wrapper-footer {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background: rgb(0, 0, 0, 0.6);
    z-index: 10000;
    overflow-y: scroll;
    overflow-x: hidden;
}

.footer-wrapper .dicuss-invite-modal {
    background: white;
    padding: 90px 40px 90px 40px;
    border-radius: 3px;
    animation: .3s fade-up;
    position: relative;
    max-width: 1200px;
    width: 90%;
    margin-top: 3.5%;
    margin-bottom: 3.5%;
}

.footer-wrapper .discuss-close-btn-wrapper {
    position: absolute;
    top: 0;
    right: 24px;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.footer-wrapper .discuss-close-btn-wrapper button.close-btn {
    font-size: 40px;
    background-color: transparent;
    border: 0;
    outline: 0;
    box-shadow: none;
    padding: 0;
    margin-right: 0;
    margin-top: 24px;
}

.footer-wrapper .modal_container-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.footer-wrapper .modal_img {
    flex-basis: 50%;
}

.footer-wrapper .modal_img img {
    width: 100%;
    margin-left: 0;
}

@media (max-width: 767px) {
    .modal_container-footer {
        flex-direction: column;
        gap: 20px;
    }

    .footer-wrapper .modal_img img {
        width: unset;
        margin-left: 0;
    }
}


/* form styling -------------------------------------------------- */
.footer-wrapper .modal_container-footer h2{
    margin-bottom: 5rem;
    font-size: 3rem;
    color: #161616;
}
.footer-wrapper .discuss-modal-wrapper-footer form {
    width: 80%;
}
.footer-wrapper .discuss-modal-wrapper-footer form label {
    color: #161616;
    /* display: block; */
}
.footer-wrapper  .flex-block {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    flex-direction: row!important;
}

.thank-you-message p{
    color: #161616;
}

.footer-wrapper .discuss-modal-wrapper-footer .form-field input {
    padding-left: 10px !important;
    margin: 5px 0 15px 0 !important;
    width: 100%!important;
    box-shadow: 0px 3px 6px #573BD333;
    height: 45px!important;
}

.footer-wrapper .discuss-modal-wrapper-footer input::placeholder, .footer-wrapper .discuss-modal-wrapper-footer textarea::placeholder {
    font-weight: 500;
    font-size: 1.8rem;
    font-family: 'Lato', sans-serif;
    color: #161616;
    opacity: 0.4;
}

.footer-wrapper .discuss-modal-wrapper-footer .form-field textarea {
    text-align: left;
    width: 100%;
    padding-left: 10px;
    color: #161616;
    margin: 5px;
    box-shadow: 0px 3px 6px #573BD333;
    border: 1px solid #16161634;
    border-radius: 5px;
    margin-bottom: px;
    font-size: 2rem;
    resize: none;
    margin-left: 0;
}

.footer-wrapper .discuss-modal-wrapper-footer .form-field textarea:focus {
    outline: 0;
    border: 1px solid #0a2854;
}

.footer-wrapper .discuss-modal-wrapper-footer input[type="submit"]{
    padding: 10px 30px!important;
}

.footer-wrapper  p.errormessage{
    font-size: 1.6rem!important;
    font-family: 'Lato', sans-serif!important;
    margin: 0!important;
    margin-bottom: 20px!important;
    color: red;
}

.footer-wrapper  .form-field > div{
    display: flex;
    flex-direction: column;
}

.footer-wrapper  .form-field.flex-block > div{
    width: 100%;
}

.footer-wrapper .thank-you-message{
    display: none;
    text-align: center;
}

.footer-wrapper .thank-you-message-show{
    display: block;
}

.footer-wrapper .thank-you-message img{
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
}

@media(max-width:1023px){
    .modal_img{
        display: none!important;
    }
}

@media(max-width:767px){
    .footer-wrapper .discuss-modal-wrapper-footer form{
        width: 100%;
    }
    .footer-wrapper .flex-block{
        flex-direction: column!important;
        gap: 0!important;
    }
}
