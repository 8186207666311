.scanning-loader-2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    height: 100%;
}

.spinner-8 {
    width: 70px;
    height: 70px;
    --c: radial-gradient(farthest-side, #3740ff 92%, #0000);
    background:
        var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    animation: s7 1s infinite;
}

@keyframes s7 {
    to {
        transform: rotate(.5turn)
    }
}