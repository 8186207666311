.invite-user-popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(255, 255, 255, 1);
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-in-out;
    overflow-y: scroll;
    padding: 50px;
}

.invite-user-popup-wrapper-show {
    opacity: 1;
    visibility: visible;
    animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.close-invite-popup {
    width: 80%;
    margin: auto;
    margin-top: 50px;
}

.close-invite-popup img {
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: transparent;
    padding: 18px;
    z-index: 10;
    border: 2px solid #161616;
}

.invite-user-wrapper-div {
    width: 80%;
    height: 90%;
    margin: auto;
    text-align: left;
    margin-top: 60px;
}

.invite-user-wrapper-div h3 {
    font-size: 3rem;
}

.invite-user-wrapper-inside-heading p {
    color: #4C545E;
}

.select-user-type-radios h4{
    font-size: 2.4rem;
    margin-top: 70px;
    color: #161616;
    font-weight: 600;
}

.select-user-type-radios form{
    border: 1px solid #4E545D65;
    max-width: 70%;
    padding: 30px 40px;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    gap: 80px;
}

.invite-user-wrapper-div [type="radio"]:checked+label, .invite-user-wrapper-div [type="radio"]:not(:checked)+label {
    font-weight: 600;
    color: #161616;
    font-family: 'Lato', sans-serif;
}

.select-user-type-radios [type="radio"]:checked+label:after, .select-user-type-radios [type="radio"]:not(:checked)+label:after {
    background-color: #FFDF1A;
}


.enter-emails-section h4{
    font-size: 2.4rem;
    margin-top: 70px;
    color: #161616;
    font-weight: 600;
}

.invite-user-wrapper-div .form-field {
    display: flex;
}

.send-invite-button{
    margin-top: 30px;
}

@media(max-width:450px) {
    .invite-user-wrapper-div {
        min-width: unset;
    }
}

@media(max-width:767px){
    .select-user-type-radios form{
        max-width: 100%;
    }
    .email-picker{
        padding: 10px 20px!important;
        max-width: 100%!important;
    }
    .invite-user-popup-wrapper{
        padding: 0;
    }
    .select-user-type-radios h4,.enter-emails-section h4{
        margin-top: 50px;
    }
    .invite-user-wrapper-div{
        margin-bottom: 50px;
    }
}

.cloud-assessment-title h2 {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    Padding-bottom: 4rem !important;
}

.workspace-setting-invite-new-user button:disabled {
    background-color: #f0f2f4;
    color: #A3A6B2;
}