.no-project-wrapper{
    min-height: 79vh;
    padding: 10rem 0;
}

.no-project-text-side{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 60px;
}

.no-project-text-side button{
    border: none;
    outline: none;
    background-color: #FFE01B;
    padding: 13px 30px;
    border-radius: 5px;
    color: #161616;
    margin-top: 4rem;
    align-self: flex-start;
    font-weight: 500;
    font-size: 1.8rem;
}

.no-project-text-side h2{
    font-size: 3.1rem;
    font-weight: 700;
    color: #161616;
}

@media(max-width:767px){

    .no-project-imageside{
        text-align: center;
    }
    .no-project-imageside img{
        max-width: 240px;
        margin: auto;
    }
    .no-project-text-side{
        margin-left: 0;
        margin-top: 6rem;
        align-items: center;
        text-align: center;
    }
    .no-project-text-side button{
        align-self: center;
    }
}