.image-section-wrapper:nth-of-type(1) {
    padding-top: 0px;
}

.image-section-wrapper {
    padding-top: 30px;
}

.image-section-wrapper h2 {
    font-size: 2.3rem;
    font-weight: 650;
}

.row.image-section-wrapper-row {
    margin-left: -10px;
    margin-right: -10px;
}

.box-wrapper {
    padding: 10px;
    height: 100%;
}

.box-wrapper-inside {
    background-color: #fff;
    box-shadow: 0px 3px 6px #573BD31A;
    border-radius: 5px;
    height: 100%;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
}

.box-wrapper-inside img {
    text-align: center;
    width: 40%;
}

.box-wrapper-inside .center {
    text-align: center;
    padding: 30px 0;
}

.box-wrapper-inside .center p {
    margin: 0rem;
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 600;
    color: #1C1E21;
}