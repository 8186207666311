.delete-project-list-popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(19, 19, 34, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-in-out;
    /*make it smooth*/

}

.delete-project-list-popup-wrapper-show {
    opacity: 1;
    visibility: visible;
    animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.delete-project-popup-inside {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2d1d0b1a;
    border-radius: 5px;
    padding: 30px 50px;
    transform: translateY(80px);
    opacity: 0;
    transition: 1s ease-in-out;
    visibility: hidden;
    position: relative;
    z-index: 1;
}

.delete-project-list-popup-wrapper-show .delete-project-popup-inside {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}


.delete-popup-close-button {
    text-align: right;
    margin-bottom: 2rem;
}

.delete-popup-description-side {
    text-align: center;
}

.delete-popup-close-button img {
    cursor: pointer;
    margin-right: -30px;
}

.delete-popup-description-side p {
    margin: 1rem 0;
    color: #1C1E21;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.delete-popup-description-side span {
    color: #1C1E21;
}

.delete-popup-description-side button {
    background: #3D2CB2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: block;
    border: none;
    outline: none;
    color: #fff;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding: 12px 0;
    margin-top: 2rem;
}

@keyframes anvil {
    0% {
        transform: scale(5) rotate(0);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    50% {
        transform: scale(1) rotate(-0.2deg);
        opacity: 1;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0.5);
    }

    75% {
        transform: scale(1) rotate(0.2deg);
        opacity: 1;
        box-shadow: 0 0 250px rgba(241, 241, 241, 0.5);
    }

    100% {
        transform: scale(1) rotate(0);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

@keyframes pop-swirl {
    0% {
        transform: scale(0) rotate(360deg);
    }

    60% {
        transform: scale(0.8) rotate(-10deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}