/* Solution Dropdown */

.solution-dropdown-textsideImage {
    background-color: #F0F2F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.nav-button a {
    color: #2d1d0b;
}

.nav-button:hover a {
    color: #fff;
}

.nav-ul li .nav-megamenu-right img {
    display: none;
}

.nav-ul li:hover .nav-megamenu-right img {
    display: block;
}

.solution-dropdown-textsideImage img {
    width: 150px;
    margin-bottom: 3%;
}

.solution-dropdown-textsideImage p {
    margin-bottom: 0;
}

.row.solutions-dropdown-listside-row {
    margin: 6rem 0rem;
    margin-left: -15px;
    margin-right: -15px;
}

.solutions-dropdown-listside {
    height: 100%;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    padding: 15px;
    border-radius: 5px;
}

.solutions-dropdown-listside:hover {
    background-color: #F0F2F4;
}

.solutions-dropdown-listside h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin: 0;
    line-height: 160%;
}

.solutions-dropdown-listside img {
    min-height: 40px !important;
    width: 44px !important;
    height: 44px !important;
}

.solutions-dropdown-listside p {
    margin-bottom: 0;
    font-size: 1.7rem;
}

.nav-li a img {
    transition: 0.5s ease-in-out;
}

.open-dropdown {
    transition: 0.5s ease-in-out;
    transform: rotate(180deg);
}

.usecase-dropdown {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 15px 30px
}

.usecase-dropdown img {
    width: 55px
}

.usecase-dropdown h4 {
    font-size: 2rem;
    line-height: 125%;
    margin-bottom: 0
}

.usecase-dropdown-row .col-lg-4.col-md-4.col-sm-4.col-12.padd-30 {
    padding: 40px 15px 0 !important
}

.usecase-dropdown:hover {
    background-color: #f0f2f4;
    border-radius: 10px
}

.row.usecase-dropdown-row {
    padding: 0 0 40px;
    margin-left: -15px;
    margin-right: -15px
}
.solutions-dropdown-listside:hover h4 {
    text-decoration: underline;
}