.dashboard-content-body {
    margin-left: 240px;
    padding-top: 70px;
    display: block;
    min-height: calc(100vh - 140px);
    position: relative;
    padding-bottom: 70px;
    -webkit-transition: margin 0.4s;
    transition: margin 0.4s;
  }
  .main-content-wrapper {
    display: block;
  }
  .dashboard-content-container{
    padding: 20px;
  }
  .dashboard-content-body.closed {
    margin-left: 70px;
  }