.dashboard-header-top {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #573BD31A;
    padding: 20px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
}
.exist_workspace_message {
    margin-top: 2rem;
    color: #161616
}
.dashboard-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.errors.has-error, .center-error.form-error p {
    color: #dc3545;
    display: block;
    text-align: left;
}
/* workspace list--------------------------------------------------------- */
.dropdown {
    position: relative;
    width: 300px;
    height: 50px;
}
.input.has-error{
    border-color: #dc3545 !important;
    border: 1px solid #dc3545 !important;
}
.dropdown::before {
    content: "";
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 1000;
    width: 10px;
    height: 10px;
    border: 2px solid #161616;
    border-top: 2px solid #F0F2F4;
    border-right: 2px solid #F0F2F4;
    transform: rotate(-45deg);
    transition: 0.5s;
    pointer-events: none;
}

.dropdown.active::before {
    top: 26px;
    transform: rotate(-225deg);
}

.dropdown .text-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #F0F2F4;
    border: none;
    outline: none;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    padding: 12px 20px;
    border-radius: 5px;
    color: #161616;
    font-size: 2rem;
    font-weight: 650;
    font-family: 'Lato', sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dropdown .options {
    position: absolute;
    top: 70px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 3px 6px #2D1D0B1A;
    border-radius: 10px;
    overflow: hidden;
    display: none;
    max-height: 350px;
    overflow-y: auto;
}

.dropdown.active .options {
    display: block;
}

.dropdown .options div {
    padding: 12px 20px;
    cursor: pointer;
    color: #161616;
    word-break: break-word;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}

.dropdown .options div:hover {
    background: #F0F2F4;
    color: #161616;
}

.dropdown .options div ion-icon {
    position: relative;
    top: 4px;
    font-size: 1.2em;
    margin-right: 10px;
}
/* workspace list--------------------------------------------------------- */

.new-workspace-side{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-workspace-wrapper{
    margin-right: 15px;
    position: relative;
}

.new-workspace-inside{
    background: #F0F2F4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px 15px;
    color: #161616;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 650;
    font-family: 'Lato', sans-serif;
}

.new-workspace-inside img{
    width: 15px;
    transition: 0.5s ease-in-out;
    margin-left: 20px;
}

.new-workspace-popup-modal-show .new-workspace-inside img{
    transform: rotate(45deg);
}


.new-workspace-popup-modal{
    position: absolute;
    top: 70px;
    right: 0;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 3px 6px #2D1D0B1A;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    width: 350px;
    border-radius: 5px;
    z-index: 10;
}

.new-workspace-popup-modal-show .new-workspace-popup-modal{
    opacity: 1;
    visibility: visible;
    transform: translate(0);
}

.new-workspace-popup-modal img{
    position: absolute;
    padding: 16px 0 10px 20px;
    width: 40px;
}

.new-workspace-popup-modal input[type="text"] {
    text-align: left;
    height: 45px;
    border-radius: 5px;
    width: 100%;
    padding-left: 50px;
    color: #1C1E21;
    margin-bottom: 5px;
    border: 1px solid #16161634;    
    margin-left: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
}

.new-workspace-popup-modal input:focus {
    outline: none;
    border: 1px solid #3D2CB2;
}


.new-workspace-popup-modal input[type='submit']{
    margin: 10px 0 0;
    font-size: 1.5rem;
    padding: 12px 20px;
    background-color: #3D2CB2!important;
    color: #fff;
}

.new-workspace-popup-modal input::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #1C1E21;
    opacity: 0.5;
}

/* ------------------------------------------------------------------------------------- */
.profile-side-wrapper{
    position: relative;
}

.profile-avatar-wrapper{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3D2CB2;
    cursor: pointer;
}

.profile-avatar-popup-modal .profile-avatar-wrapper{
    cursor: default;
}

.profie-avatar-heading-side .profile-avatar-wrapper{
    margin-right: 15px;
}

.profile-avatar-wrapper p{
    margin-bottom: 0;
    color: #fff;
    text-transform: capitalize;
}

.profie-avatar-heading-side > div:nth-of-type(2) p{
    margin-bottom: 0;
}

.profile-avatar-popup-modal{
    position: absolute;
    top: 70px;
    right: 0;
    padding: 30px 20px;
    background: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    border-radius: 5px;
}

.profile-avatar-popup-modal-show .profile-avatar-popup-modal{
    opacity: 1;
    visibility: visible;
    transform: translate(0);
}

.profie-avatar-heading-side{
    display: flex;
    align-items: center;
}

.avatr-username{
    margin-bottom: 0;
    font-size: 1.6rem;
    color: #2D1D0B;
    font-weight: 500;
}

.avatr-email{
    font-weight: 400;
    font-size: 1.6rem;
    color: #7C6342;
}

.profile-avatar-description{
    margin-top: 2rem;
}

.profile-avatar-description p{
    color: #5B4423;
    font-weight: 400;
    font-size: 1.5rem;
}

.avtar-logout-side p{
    color: #3D2CB2;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0;
    cursor: pointer;
    display: inline;
}

.filter-blur{
    filter: blur(3px);
}

@media(max-width:1023px){
    .dashboard-header-top{
        display: none;
    }
    .dropdown{
        width: 100%;
        height: 65px;
    }
    .dropdown .text-box{
        padding: 8px 24px;
        font-size: 2rem;
        font-weight: 400;
        background-color: #fff;
        color: #161616;
    }
    .dropdown .options{
        width: 50%;
        z-index: 10;
        left: 10px;
    }
    .dropdown .options div{
        padding: 5px 24px;
        height: 50px;
    }
    .dropdown::before{
        top: 26px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
    }
    .new-workspace-wrapper{
        margin-right: 0;
    }
    .new-workspace-inside img{
        float: right;
        margin-top: 18px;
        width: 13px;
    }
    .new-workspace-inside{
        height: 65px;
        padding: 7px 18px 7px 24px;
        font-size: 2rem;
        background-color: #fff;
        color: #161616;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    .color-orange a{
        color: #3D2CB2!important;
        font-weight: 500!important;
        font-size: 2.4rem!important;
    }
    .profie-avatar-heading-side{
        padding: 10px 25px;
    }
    .new-workspace-popup-modal{
        left: 10px;
    }
    .nav-ul {
        padding-bottom: 80px;
    }
    .new-workspace-popup-modal input[type='submit'] {
        padding: 0px 20px;
    }
    .profile-avatar-wrapper p{
        font-size: 2rem;
        color: #fff!important;
        padding: 0!important;
        width: auto!important;
    }
    .avatr-username,.avatr-email{
        font-size: 2rem;
        padding:0px!important;
    }
    .header .item a{
        font-size: 2rem;
    }
    .header .item a p{
        font-size: 2rem;
    }
    .profile-avatar-wrapper{
        margin-right: 15px;
        flex-basis: initial;
        flex-shrink: 0;
    }
    .profile-avatar-wrapper{
        width: 55px;
        height: 55px;
    }
}

@media(max-width:400px){
    .new-workspace-popup-modal{
        right: 10px;
        width: unset;
    }
}