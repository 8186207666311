.scanned-heading-with-filter {
    position: relative;
}

.scanned-projects-filters {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-content: center;
}

.scanned-projects-filters p {
    margin-bottom: 0;
}

.filters-arrow {
    width: 3rem;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.filters-arrow-show{
    -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.filters-modal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2D1D0B1A;
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    width: 230px;
    max-height: 200px;
    right: 0;
    top: 4rem;
    padding: 20px 20px;
    overflow-x: scroll;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: 0.4s ease-in-out;
}

.filters-modal::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.filters-modal {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.filters-modal-show {
    visibility: visible;
    opacity: 1;
    transform: translate(0px);
}

.filters-modal .form-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.filters-modal .form-field label {
    color: #7C6342;
    font-size: 1.3rem;
}

.filters-modal .form-field:last-of-type {
    margin-bottom: 0px;
}