p, input {
    font-family: 'Lato', sans-serif;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

p.error {
    font-size: 1.4rem!important;
    margin-top: 0.5rem!important;
}

.form-field img {
    position: absolute;
    padding: 28px 0 10px 18px;
    width: 40px;
}

.form-field:nth-of-type(3) img{
    padding-top: 24px;
}

.inline-fields img {
    padding: 22px 0 8px 20px;
}

.form-field p {
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
    color: #1450A8;
    margin-top: 10px;
}

.form-field input[type='text'], .form-field input[type='email'], .form-field input[type='password'] {
    text-align: left;
    height: 55px;
    width: 100%;
    padding-left: 50px;
    color: #1C1E21;
    margin: 5px;
    box-shadow: 0px 3px 6px #16161626;
    border: 1px solid #16161634;
    border-radius: 5px;
    /* margin-bottom: px; */
    font-size: 2rem;
}


.inline-fields {
    display: flex;
    justify-content: space-between;
}

.first-field, .last-field {
    width: 49%;
}

.form-field input:focus {
    outline: none;
    border: 1px solid #0A2854;
}

.form-field input::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: 380;
    color: #1C1E21;
    font-size: 1.8rem;
    opacity: 0.8;
}

input[type='submit'] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #f5df4d;
    border: none;
    color: #161616;
    padding: 10px 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 2rem;
    border-radius: 32px;
    text-transform: uppercase;
}

@media (max-width:767px) {
    .first-field, .last-field {
        width: 100%;
        margin-bottom: 0px;
    }
    .inline-fields {
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 0;
    }
    .first-field {
        margin-bottom: 10px;
    }
    .form-field input[type='text'], .form-field input[type='email'], .form-field input[type='password'] {
        margin-bottom: 10px;
    }
}
