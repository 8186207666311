html {
  font-family: sans-serif;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #334960;
  background-color: #ffffff;
  margin: 0;
  overflow-x: hidden;
  height: 100%;
  padding: 0;
}

h1 {
  color: #161616;
  font-size: 6rem;
}

h2 {
  font-size: 3.6rem;
  color: #161616;
  font-weight: 750;
  line-height: 130%;
}

h3 {
  color: #161616;
  line-height: 130%;
  font-size: 2.6rem;
}

h4, h5, span {
  color: #161616;
}

h1, h2, h3, h4, h5 {
  font-family: 'Lato', sans-serif;
  margin: 0 0 1rem;
}

p {
  color: #1C1E21;
  font-size: 1.8rem;
  line-height: 155%;
  margin: 0 0 1rem;
  font-family: 'Lato', sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  display: block;
}

a {
  color: #fb4028;;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

a:hover, a:focus, a:active {
 color: #fb4028;;
 text-decoration: none;
  outline: none;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

@media (max-width:768px) and (max-width:1023px) {
  html {
    font-size: 8px;
  }
}

@media (max-width:767px) {
  html {
    font-size: 7px;
  }
}