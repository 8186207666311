/* Scanned Projects */

.scanned-projects-section {
    margin-top: 50px;
}

.scanned-projects-section h2 {
    font-size: 2.3rem;
    font-weight: 650;
    margin-bottom: 2rem;
}

.scanned-heading-with-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row.scanned-projects-row {
    margin-left: -15px;
    margin-right: -15px;
}

.scanned-projects-cards-outside {
    height: 100%;
    padding: 15px;
    border-radius: 5px;
}

.scanned-projects-cards-inside {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #573BD31A;
    padding: 25px 35px;
    height: 100%;
    border-radius: 5px;
}

.scanned-projects-cards-heading {
    text-align: center;
}

.scanned-projects-cards-heading img {
    width: 100px;
}

.scanned-projects-cards-heading h3 {
    margin: 0;
    margin-top: 15px;
    font-size: 1.6rem;
}

.scanned-projects-cards-heading p {
    font-size: 1.4rem;
    color: #4C545E;
    margin: 0;
}

.time-last-scanned {
    margin-top: 5px;
}

.time-last-scanned p {
    margin: 0rem;
    color: #4C545E!important;
    font-size: 1.6rem!important;
}

.scanned-projects-category-list {
    margin-top: 30px;
}

.list-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list-items p {
    color: #1C1E21;
    font-size: 1.8rem;
}

.scanned-projects-category-list button {
    margin-top: 10px;
    border: none;
    padding: 12px 0;
    width: 100%;
    border-radius: 5px;
    color: #3D2CB1;
    background: none;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scanned-projects-category-list button img{
    width: 30px;
    height: 30px;
    padding: 7px;
    border-radius: 50%;
    margin-left: 20px;
    background-color: #FFE01B;
    display: inline-block;
}

/* Scanned Stats */

.Scanned-stats-and-Vulnerabilities-section h2 {
    font-size: 2.3rem;
    font-weight: 650;
    margin-bottom: 2.8rem;
}

/* Charts */

.highcharts-credits {
    display: none!important;
}

.scanned-stats-cards-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.scanned-stats-cards-left {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #573BD31A;
    border-radius: 5px;
}

.scanned-stats-cards-right {
    height: 100%;
    width: 100%;
}

.highcharts-background {
    fill: transparent;
}

.highcharts-legend-item text {
    color: #1C1E21!important;
    fill: #1C1E21!important;
    font-family: 'Lato', sans-serif;
    font-weight: 600!important;
    font-size: 1.3rem!important;
}

.scanned-stats-cards-style-common {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #573BD31A;
    padding: 20px 20px 20px 20px;
    height: 200px;
    border-radius: 5px;
}

.scanned-stats-cards-style-common:nth-of-type(1) {
    margin-bottom: 10px;
}

.scanned-stats-cards-style-common img {
    width: 70px;
    height: 75px;
}

.scanned-stats-cards-style-common h3 {
    color: #F3CE29;
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 2.8rem;
}

.scanned-stats-cards-style-common p {
    font-size: 1.6rem;
    font-weight: 600;
}

@media (min-width:1641px){
    .scanned-stats-cards-style-common{
        display: flex;
        gap:20px;
        align-items: center;
        text-align: center;
    }
    .scanned-stats-cards-style-common p{
        font-size: 2rem;
        margin-bottom: 25px;
    }
    .scanned-stats-cards-style-common > div {
        flex: 1 1 100%;
    }
}

@media(max-width:1140px) and (min-width:768px){
    .scanned-stats-cards-wrapper {
        display: flex;
        flex-direction: column;
    }
    .highcharts-container.highcharts-3d-chart {
        margin: auto!important;
        text-align: center!important;
    }
    .scanned-stats-cards-right{
        display: flex;
        gap: 10px;
    }
    .scanned-stats-cards-style-common{
        height: 230px;
        flex-basis:50%;
    }
    .Vulnerabilities-section-wrapper{
        height:650px!important;
    }
}

@media (max-width:450px) {
    .scanned-stats-cards-style-common {
        width: 50%;
    }
    .scanned-stats-cards-wrapper{
        flex-direction: column;
    }
    .scanned-stats-cards-right {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .scanned-stats-cards-style-common:nth-of-type(1) {
        margin-bottom: 0;
    }
    .highcharts-container.highcharts-3d-chart {
        margin: auto!important;
        text-align: center!important;
    }
    .scanned-stats-cards-style-common img {
        height: 90px;
    }
}

/* Vulnerabilities start */

.Vulnerabilities-section-wrapper {
    height: 410px;
    overflow-y: scroll;
}

.Vulnerabilities-section-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.Vulnerabilities-section-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Vulnerabilities-list {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #573BD31A;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.Vulnerabilities-list:last-of-type {
    margin-bottom: 0;
}

.Vulnerabilities-list p {
    margin-bottom: 0rem;
    font-size: 1.6rem;
    color: #4C545E;
}

.Vulnerabilities-list-image img {
    width: 45px;
}

.Vulnerabilities-name p {
    color: #161616;
    font-weight: 600;
    font-size: 2rem;
}

@media (max-width:767px) {
    .Vulnerabilities-wraper {
        margin-top: 50px;
    }
    .scanned-stats-cards-style-common p {
        font-size: 1.8rem;
    }
    .list-items p{
        font-size: 1.8rem;
    }
    .scanned-projects-category-list button{
        font-size: 1.8rem;
        justify-content: center;
    }
    .scanned-projects-cards-heading h3{
        font-size: 2.2rem;
    }
    .scanned-projects-cards-heading p{
        font-size: 1.8rem!important;
    }
    .Vulnerabilities-name p,.Vulnerabilities-list p{
        font-size: 1.8rem;
    }
}