@keyframes fade-up {
    from {
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
        -o-transform: translateY(-200px);
        -ms-transform: translateY(-200px);
        -moz-transform: translateY(-200px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -o-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -moz-transform: translateY(0px);
        opacity: 1;
    }
}

.score-modal::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
    background-color: #1034FE;
}

.score-modal {
    background: white;
    padding: 120px 40px 120px 40px;
    border-radius: 3px;
    animation: .3s fade-up;
    position: relative;
    max-width: 1200px;
    width: 93%;
    margin: 15px;
}

.checkscore-modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background: rgb(0, 0, 0, 0.6);
    z-index: 10000;
    overflow-y: scroll;
    overflow-x: hidden;
}

.close-btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.close-btn-wrapper button.close-btn {
    font-size: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin-right: 700px;
    margin-top: 20px;
}

.heading-side-score{
    padding: 40px 0;
}

.heading-side-score h3{
    margin-bottom: 0;
}

.score-selection-cards-wrapper {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #351C0B4D;
    cursor: pointer;
}

.score-selection-cards-wrapper img {
    max-width: 20rem;
}

.website-score-form{
    display: block;
}
.flex-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-center h3{
    margin-bottom: 5rem;
}
.score-side-image-wrapper{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@media(max-width:767px){
    .score-side-image-wrapper{
        display:none;
    }
    .flex-center h3 {
        margin-bottom: 9rem;
      }
}